<template>
  <div class="sobre-nosotros">
    <div v-if="loading" class="loading-indicator">
      <LoadingIndicator />
    </div>
    <div v-else>
      <div class="carousel-container">
        <CarouselComponent :slides="allViews.sliders" />
        <div v-if="allViews.sliders.length > 0" class="title-overlay">
            <h3>Nuestra Institución</h3>
        </div>
      </div>
      <ServicesComponent v-if="allViews.cards.length > 0" :novedades="allViews.cards" />
      <BlockComponent
        v-for="block in allViews.blocks"
        :key="block.id"
        :block="block"
      />
      <ButtonLinkComponent v-if="allViews.buttonLinks.length > 0" :buttonLinks="allViews.buttonLinks" />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useImageLoader } from '@/composables/useImageLoader';
import { fetchViewByUrl } from "@/services/api/view.js";

import CarouselComponent from "@/components/carousel/CarouselComponent.vue";
import BlockComponent from "@/components/block/BlockComponent.vue";
import ButtonLinkComponent from "@/components/bottonLink/ButtonLinkComponent.vue";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";
import ServicesComponent from "@/components/servicios/Servicio.vue";


const { loading, allViews, loadImages } = useImageLoader();

onMounted(() => {
  loadImages(fetchViewByUrl);
});
</script>

<style scoped>
.sobre-nosotros {
  margin: 0 auto;
  position: relative;
}

.carousel-container {
  position: relative;
}

.title-overlay {
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 31, 91, 0.9);
  color: #ffffff;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
  padding-left: 16%;
}

.title-overlay h3 {
  text-align: left;
  padding: 0;
}


</style>

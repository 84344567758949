<template>
  <section class="comision-directiva" style="margin-top: 35px">
    <div class="container">
      <div class="row">
        <div
          v-for="(link, index) in buttonLinks"
          :key="index"
          class="col-12 col-md-4 text-center"
        >
          <a
            v-if="!isLoggedIn && link.link !== null"
            :href="link.link"
            class="d-block"
          >
            <img :src="link.image" />
            <h5 :class="{ 'link-type-2-h5': link.type === 2 }">{{ link.title }}</h5>
            <button
              v-if="link.type === 2"
              class="btn btn-custom mt-2"
            >
              INGRESAR
            </button>
          </a>

          <a
            v-else-if="link.link !== null"
            :href="link.link"
            class="d-block"
          >
            <img :src="link.image" />
            <h5 :class="{ 'link-type-2-h5': link.type === 2 }">{{ link.title }}</h5>
            <button
              v-if="link.type === 2"
              class="btn btn-custom mt-2"
            >
              INGRESAR
            </button>
          </a>

          <a
            v-else
            href="#"
            @click.prevent="openPdf(link.file)"
            class="d-block"
          >
            <img :src="link.image" />
            <h5 :class="{ 'link-type-2-h5': link.type === 2 }">{{ link.title }}</h5>
            <button
              v-if="link.type === 2"
              class="btn btn-custom mt-2"
            >
              INGRESAR
            </button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ButtonLinkComponent",
  props: {
    buttonLinks: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    console.log("props.buttonLinks", props.buttonLinks);

    const isLoggedIn = ref(false);

    const openPdf = (file) => {
      if (file) {
        window.open(file, "_blank"); 
      } else {
        console.error("No se encontró el archivo PDF.");
      }
    };

    return {
      isLoggedIn,
      openPdf,
    };
  },
};
</script>

<style scoped>
a {
  background-color: transparent;
  text-decoration: none;
  display: block;
}

h5 {
  color: #00a0e1;
  text-transform: uppercase;
  margin-top: 15px;
}

.comision-directiva {
  margin-bottom: 50px;
}

.btn-custom {
  background-color: #00999C;
  border-color: #00999C;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 0;
  padding: 5px 35px;
}

.btn-custom:hover {
  background-color: #007b7c;
  border-color: #007b7c;
  color: #e0e0e0;
}

.link-type-2-h5 {
  padding: 15px 0;
  font-weight: 400;
  color: #00a0e1;
  text-transform: uppercase;
}
</style>

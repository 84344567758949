<template>
  <div> 


<p>Comision Directiva</p>
    
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ComisionDirectivaView",
  setup() {
    const route = useRoute();
    const tipo = ref("");


    onMounted(() => {
      tipo.value = route.query.tipo || "";
    });

    return {

    };
  },
};
</script>

<style scoped>

</style>

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import SobreNosotros from "@/views/SobreNosotros.vue";
import DivisionesView from "@/views/Divisiones.vue";
import SociosView from "@/views/Socios.vue";
import AsesoriaLegal from "@/views/AsesoriaLegal.vue";
import AsesoriaImpositiva from "@/views/AsesoriaImpositiva.vue";
import AsesoriaRegistral from "@/views/AsesoriaRegistral.vue";
import CapacitacionView from "@/views/Capacitacion.vue";
import ContactoView from "@/views/Contacto.vue";
import PreciosView from "@/views/PreciosView.vue"; 
import ComisionDirectivaView from "@/views/ComisionDirectivaView.vue"; 

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/sobre-nosotros",
    name: "sobre-nosotros",
    component: SobreNosotros,
  },
  {
    path: "/divisiones",
    name: "divisiones",
    component: DivisionesView,
  },
  {
    path: "/socios",
    name: "socios",
    component: SociosView,
  },
  {
    path: "/asesoria-legal",
    name: "asesoria-legal",
    component: AsesoriaLegal,
  },
  {
    path: "/asesoria-impositiva",
    name: "asesoria-impositiva",
    component: AsesoriaImpositiva,
  },
  {
    path: "/asesoria-registral",
    name: "asesoria-registral",
    component: AsesoriaRegistral,
  },
  {
    path: "/capacitacion",
    name: "capacitacion",
    component: CapacitacionView,
  },
  {
    path: "/contacto",
    name: "contacto",
    component: ContactoView,
  },
  {
    path: "/guia-oficial-de-precios", 
    name: "precios",
    component: PreciosView,
  },
  {
    path: "/comision-directiva", 
    name: "comisionDirectiva",
    component: ComisionDirectivaView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

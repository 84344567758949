import { ref } from 'vue';

export function useImageLoader() {
  const loading = ref(true);
  const filteredViews = ref([]);
  const allViews = ref({ sliders: [], cards: [], blocks: [], buttonLinks: [] });

  const loadImages = async (fetchViewByUrl) => {
    try {
      const response = await fetchViewByUrl();
      const views = response.data;

      // Cargar sliders
      const sliders = views.sliders;
      const imagePromises = sliders.map((slide) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = slide.image;
          img.onload = resolve;
          img.onerror = resolve;
        });
      });

      // Esperar a que todas las imágenes de sliders se carguen
      await Promise.all(imagePromises);

      // Asignar sliders, cards, bloks y buttonLinks a allViews
      allViews.value.sliders = sliders;
      allViews.value.cards = views.cards;

      allViews.value.blocks = views.blocks; 

      allViews.value.buttonLinks = views.buttonLinks

    } catch (error) {
      console.error('Error al cargar los views:', error);
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    filteredViews,
    allViews,
    loadImages,
  };
}

<template>
  <div>
    <div class="container first-section">
      <div
        v-for="section in singleColumnSections"
        :key="section.id"
        class="single-column"
        :class="{ 'content-text': section.content && !section.image }"
      >
        <div v-if="section.content && !section.image" v-html="section.content"></div>
        <div v-else-if="section.image && !section.content" class="background-image" :style="{ backgroundImage: `url(${section.image})` }"></div>
      </div>
    </div>

    <div class="second-section">
      <div class="container">
        <div class="row">
          <div v-for="section in twoColumnSections" :key="section.id" class="col-md-6 col-sm-12"> 
            <div v-if="section.content && !section.image" v-html="section.content" class="section-column"></div>
            <div v-else-if="section.image && !section.content" class="background-image" :style="{ backgroundImage: `url(${section.image})` }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "BlockComponent",
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    console.log(props.block);

    const singleColumnSections = computed(() =>
      props.block.sections
        .filter((section) => section.type === 1)
        .sort((a, b) => a.order - b.order)
    );

    const twoColumnSections = computed(() =>
      props.block.sections
        .filter((section) => section.type === 2)
        .sort((a, b) => a.order - b.order)
    );

    return {
      singleColumnSections,
      twoColumnSections,
    };
  },
};
</script>

<style>
.first-section {
  max-width: 1140px;
  background: #ffffff;
  padding: 75px 0;
}

@media (max-width: 768px) {
  .first-section {
    padding: 50px 20px;
  }
}

.second-section {
  background: #f9f9f9;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

@media (max-width: 768px) {
  .second-section {
    padding: 50px 20px;
  }
}

.single-column {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .single-column {
    margin-bottom: 15px;
    margin-left: 15px!important; 
    margin-right: 15px!important;
  }
}

.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .background-image {
    background-size: cover;
    min-height: 200px;
  }
}

.content-text {
  color: #757575;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
}

@media (max-width: 768px) {
  .content-text {
    font-size: 18px;
    line-height: 28px;
  }
}

.section-column {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .section-column {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.single-column {
  margin-bottom: 1.25rem; 
  margin-left: 9rem;
  margin-right: 9rem; 
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-left {
  text-align: left;
}
</style>
